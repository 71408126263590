import React from "react";
import Navbar from "components/global/navbar";
import Layout from "components/global/layout";

const terminosCondiciones = [
  {
    section: "Términos y Condiciones",
    description:
      "Para efectos de los presentes Términos y Condiciones, se establecen los significados de las siguientes palabras o términos, con independencia de su uso en plural o en singular:",
    items: [
      {
        title: "Origen.",
        description:
          "Es la ciudad en donde Aerocharter de México bajo la marca comercial Viva Cargo powered by Aerocharter realizará la recolección del ENVÍO de acuerdo con el DOMICILIO DE RECOLECCIÓN señalado por el REMITENTE (persona que envía la carga).",
      },
      {
        title: "Destino.",
        description:
          "Es la ciudad en donde la marca comercial Aerocharter de México bajo la marca comercial Viva Cargo powered by Aerocharter   realizará la entrega del ENVÍO de acuerdo con DOMICILIO DE ENTREGA señalado por el REMITENTE (persona que envía la carga).",
      },
      {
        title: "Cobertura.",
        description:
          "Se refiere a los códigos postales donde Aerocharter de México bajo la marca comercial Viva Cargo powered by Aerocharter cuenta con el servicio de entrega y/o recolección a nivel nacional y pudiendo variar por el tipo de SERVICIO contratado.",
      },
      {
        title: "Envío.",
        description:
          "Es el sobre o paquete de hasta 5 kilogramos adecuadamente embalado y rotulado, el cual será transportado y entregado por Aerocharter de México bajo la marca comercial Viva Cargo powered by Aerocharter  en el DOMICILIO DE DESTINO o en AEROPUERTO DESTINO.",
      },
      {
        title: "¿Quién envía? / Remitente.",
        description:
          "Es la persona que realiza el ENVÍO de acuerdo con los datos que aparecen en la guía.",
      },
      {
        title: "¿Quién recibe? / Destinatario.",
        description:
          "Es la persona que recibe el ENVÍO de acuerdo con los datos que aparecen en la guía.",
      },
      {
        title: "Dirección de Recolección.",
        description:
          "Es el domicilio proporcionado por el REMITENTE, que se incorpora a la GUÍA y en donde Aerocharter de México bajo la marca comercial Viva Cargo powered by Aerocharter realizará la recolección del mismo.",
      },
      {
        title: "Dirección de Destino.",
        description:
          "Es el domicilio proporcionado por el REMITENTE, que se incorpora a la GUÍA y en donde Aerocharter de México bajo la marca comercial Viva Cargo powered by Aerocharter realizará la entrega del ENVÍO.",
      },
      {
        title: "Envío en Aeropuerto.",
        description:
          "Modalidad de entrega donde el REMITENTE, acude directamente a alguna de las oficinas de Aerocharter de México bajo la marca comercial Viva Cargo powered by Aerocharter  (según listado de la página https://www.vivaaerobus.com/es-mx/servicios/viva-cargo), a dejar su ENVÍO.",
      },
      {
        title: "Recolección en Aeropuerto.",
        description:
          "Modalidad donde el DESTINATARIO con identificación oficial, acude directamente a alguna de las oficinas de Aerocharter de México bajo la marca comercial Viva Cargo powered by Aerocharter  (según listado de la página https://www.vivaaerobus.com/es-mx/servicios/viva-cargo), a recoger su ENVÍO.",
      },
      {
        title: "Tiempo Estimado de Entrega.",
        description:
          "Es el tiempo en el que se prevé que el envío sea entregado de acuerdo al SERVICIO contratado y la cobertura correspondiente.",
      },
      {
        title: "Rastreo.",
        description:
          "Sitio de internet XXXXXXX donde Aerocharter de México bajo la marca comercial Viva Cargo powered by Aerocharter pone a disposición del CLIENTE y de los DESTINATARIOS para que estos, puedan dar seguimiento a sus ENVÍOS. ",
      },
    ],
  },
  {
    section: "Descripción General de los Servicios",
    description: "Los servicios son aplicables únicamente a nivel nacional.",
    items: [
      {
        title: "Servicio - Puerta a Puerta",
        description:
          "Servicio completo que incluye la recolección del paquete en el domicilio de la persona que realiza el envío (remitente) hasta la entrega final en el domicilio de la persona que recibe el paquete (destinatario).",
      },
      {
        title: "Servicio - Puerta a Aeropuerto",
        description:
          "Incluye la recolección del paquete en el domicilio de la persona que realiza el envío y la entrega final se realiza en el aeropuerto destino en la oficina Aerocharter de México bajo la marca comercial Viva Cargo powered by Aerocharter que se haya especificado.",
      },
      {
        title: "Servicio - Aeropuerto a Puerta",
        description:
          "Recepción del paquete en el aeropuerto origen en la oficina de Aerocharter de Mëxico bajo la marca comercial Viva Cargo powered by Aerocharter y la entrega final se realiza en el domicilio del destinatario.",
      },
    ],
  },
  {
    section: "Tiempo Estimado de Entrega",
    description:
      "Para efectos de los presentes Términos y Condiciones, se establecen los significados de las siguientes palabras o términos, con independencia de su uso en plural o en singular:",
    items: [
      {
        title: "Día siguiente.",
        description:
          "Consiste en la entrega al día hábil siguiente de haber sido recolectado el paquete o recibido en el aeropuerto, en un horario de lunes a viernes, sin hora de entrega especificada y en territorio nacional.",
      },
      {
        title: "Más 2 días.",
        description:
          "Consiste en la entrega del paquete en una temporalidad de 2 días hábiles, en un horario de lunes a viernes, sin hora de entrega especificada y en territorio nacional.",
      },
    ],
  },
  {
    section: "Paquetes y/o Envío",
    description:
      "Se deberán respetar los límites de dimensiones y pesos establecidos, en caso de que el envío exceda las dimensiones o el peso podrá ser rechazado al momento de la recolección y en caso de ser recibido en el Aeropuerto deberá hacerse el cobro extra correspondiente.",
    items: [
      {
        title: "Dimensiones máximas permitidas:",
        description: `
        Alto: 30 cm
        `,
      },
      {
        description: "Largo: 30 cm",
      },
      { description: "Ancho: 30 cm" },
      {
        title: "Peso máximo permitido:",
        description: `
          5 kilogramos
        `,
      },
    ],
  },
  {
    section: "Restricción para la recolección y aceptación de envío",
    description:
      "El servicio será prestado en relación con mercancías, bienes, activos, objetos, paquetes, correo y/o cualesquiera otros similares o análogos que sean susceptibles de ser transportados y/o trasladados vía terrestre y aéreo, salvo por lo enlistado a continuación de manera enunciativa más no limitativa:",
    items: [
      {
        description:
          "a) Manejo y transportación de MERCANCÍAS PELIGROSA por ejemplo: Aerosoles, productos químicos, pilas, baterías, pinturas, solventes, artículos tóxicos, artículos de limpieza, encendedores, perfumes, corrosivos, acetonas, hielo seco, fertilizantes, aromatizantes, venenos, drogas, medicamentos controlados, cartuchos y armas de fuego, estupefacientes, lámparas de gas o queroseno, tanques con gases comprimidos, motores usados, motocicletas, artículos magnéticos, fuegos artificiales o pirotecnia, pigmentos y cualquier otro producto que en la experiencia y conocimiento del transportista pueda representar un peligro.",
      },
      {
        description:
          "b) Animales vivos (incluidos pero no limitados a mamíferos, reptiles, peces, invertebrados, anfibios, aves, insectos, larvas y pupas).",
      },
      {
        description:
          "c) Trofeos de caza (animales), partes de animales como marfil y aleta de tiburón, restos de animales o cenizas, subproductos animales.",
      },
      {
        description: "d) Cenizas o restos humanos.",
      },
      { description: "e) Lingotes (de cualquier metal precioso)." },
      {
        description:
          "f) Efectivo (moneda de curso legal - billetes de banco, billetes de divisas, monedas) y cheques de viajero.",
      },
      {
        description:
          "g) Piedras preciosas y semipreciosas sueltas (cortadas o sin cortar, pulidas o sin pulir).",
      },
      {
        description:
          "h) Armas de fuego originales y de imitación y partes de armas de fuego, municiones, explosivos / artefactos explosivos.",
      },
      {
        description:
          "i) Cualquier MERCANCÍA cuya transportación y/o traslado esté prohibido por cualquier ley, normativa o disposición gubernamental aplicable.",
      },
      {
        title: "No se aceptarán paquetes con:",
        description: "a) Cajas recicladas o usadas en mal estado.",
      },
      {
        description: "b) Empaques donde se vea el interior.",
      },
      {
        description: "c) Cajas de huevo, nuevas o usadas.",
      },
      {
        description:
          "d) En cajas que a la vista contengan símbolos de inflamable, corrosivos o peligro de explosión.",
      },
    ],
  },
  {
    section: "Precios",
    description:
      "Los precios que se presentan en la página web XXXXXX son en pesos mexicanos y están sujetos a cambios sin previo aviso.",
    items: [],
    message:
      "Es responsabilidad del cliente verificar antes de realizar la compra que el servicio que adquiere tiene cobertura a los códigos postales a los cuales desea realizar su envío. Validar la frecuencia de entrega y el servicio que aplica para cada código postal. Es importante que, para asegurar la entrega exitosa del envío, se deben proporcionar los datos completos del remitente y destinatario: Nombre, Calle, Número Exterior, Número Interior, Código Postal, Colona, Ciudad, Municipio o Alcaldía, Estado, Teléfono, Celular, Correo electrónico y referencias del domicilio.",
  },
  {
    section: "Relativo a los Métodos de Pago",
    description:
      "Para efectos de los presentes Términos y Condiciones, se establecen los significados de las siguientes palabras o términos, con independencia de su uso en plural o en singular:",
    items: [
      {
        title: "Tarjeta de Crédito y Débito.",
        description:
          "Aerocharter de México bajo la marca comercial Viva Cargo powered by Aerocharter  pone a disposición del cliente los medios de pago con tarjetas de crédito y/o débito VISA, MASTERCARD y AMERICAN EXPRESS.  Se utilizan los servicios ofrecidos por XXXXX cuya dirección electrónica es XXXXX es un procesador de pago electrónico certificado XXXXXXX  para el procesamiento de pagos realizados a través de Internet. Una vez validados los datos de la Tarjeta y aprobado el cargo por el Banco, la transacción será exitosa. En caso de que no se haya cumplido con algún filtro de seguridad o el Banco haya declinado el cargo, se le notificará al cliente directo en el Carrito de Compra y la transacción no será procesada. El cargo a la Tarjeta es en una sola exhibición, no aplica meses sin intereses, se verá reflejado en el estado de cuenta del Banco Emisor y con las condiciones de corte, pago y crédito de éste.",
      },
      {
        title: "Paypal.",
        description:
          "Gestionado directamente por el motor de pagos PayPal de México, S. de R.L. de C.V. consulta sus Términos y Condiciones en XXXXX.",
      },
    ],
  },
  {
    section: "Facturación.",
    description:
      "En caso de no solicitar factura en el mostrador no podré solicitarla posteriormente ya que esta será timbrada a Público en General, en apego a la Regla 2.7.1.21 de la Resolución Miscelánea Fiscal para el 2022.",
    items: [{ title: "" }],
  },
  {
    section: "Reclamaciones.",
    description:
      "Procederá la reclamación correspondiente cuando el reporte generado se haya concluido conforme los siguientes motivos: extravío, sustracción, daño del envío o destrucción.",
    items: [
      {
        description:
          "Si se presenta alguno de estos motivos el REMITENTE deberá enviar la reclamación al email reclamosvivacargo@aerocharter.com.mx.",
      },
      {
        description:
          "En un lapso no mayor a 14 días después de entregada la mercancía adjuntando los siguientes documentos: Carta de Reclamación (explicando los hechos). Fotos o evidencia del daño (cuando aplique).",
      },
      {
        description:
          "Después de recibido el reclamo con la información completa en un lapso no mayor a 21 días daremos respuesta.",
      },
      {
        description:
          "Para todas las reclamaciones Aerocharter de México bajo la marca comercial Viva Cargo powered by Aerocharter se aplicará bajo el artículo 63 y 66 de la Ley de Aviación Civil.",
      },
    ],
  },
];

const TerminosYCondiciones = () => {
  return (
    <>
      <Navbar />
      <Layout>
        <div className="mt-6 text-gray-600 text-sm">
          {terminosCondiciones.map((item) => (
            <ul>
              <h1 className="text-gray-700 text-center font-extrabold text-xl mb-4">
                {item.section}
              </h1>
              <p className="mb-4 text-justify">{item.description}</p>
              <p className="mb-4 text-justify font-semibold italic">
                {item.message}
              </p>
              {item.items.map((item) => (
                <li
                  key={item.title}
                  className="text-gray-600 my-4 flex items-center text-justify"
                >
                  <p>
                    <h3 className="text-gray-800 font-bold text-sm py-2">
                      {item.title}
                    </h3>
                    {item.description}
                  </p>
                </li>
              ))}
            </ul>
          ))}
        </div>
      </Layout>
    </>
  );
};

export default TerminosYCondiciones;
